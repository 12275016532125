import React, {Dispatch, SetStateAction} from 'react';

// Hooks and methods
import {useDateRangePickerContext} from './date-range-picker-context';
import {DATE_FORMAT_OPTION, formattedDate} from '@compt/utils/date-helpers';

// Components
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';

export const DateRangeDisplay = ({
  label,
  setIsOpen,
}: {
  label: string;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const {startDate, endDate} = useDateRangePickerContext();

  return (
    <button onClick={() => setIsOpen(true)}>
      <div className="flex items-center">
        <p className="label4 text-color-body1 mr-2">{label}</p>
        <div className="flex items-center border w-[265px] px-2 py-1 space-x-2 rounded-lg">
          <ComptSvgIcon iconName="calendar" labelHidden={false} ariaLabel="calendar-icon" />
          {startDate !== null ? (
            <p className="body3 text-color-body1">
              {formattedDate(startDate, DATE_FORMAT_OPTION['mm/dd/yyyy'])}
            </p>
          ) : (
            <p className="body3 text-color-tertiary">Start date</p>
          )}
          <span className="px-2">-</span>
          <ComptSvgIcon iconName="calendar" labelHidden={false} ariaLabel="calendar-icon" />
          {endDate !== null ? (
            <p className="body3 text-color-body1">
              {formattedDate(endDate, DATE_FORMAT_OPTION['mm/dd/yyyy'])}
            </p>
          ) : (
            <p className="body3 text-color-tertiary">End date</p>
          )}
        </div>
      </div>
    </button>
  );
};
